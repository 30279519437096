import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import confirm from "../../Images/confirm.png";
import logo from "../../Images/logo.png";
import { Navigate, useNavigate } from "react-router-dom";
import {IoIosArrowForward} from 'react-icons/io'
const ConfirmationPassword = () => {
    let navigate = useNavigate();

  return (
    <div>
      <div className="loginContainer">
        <div className="loginContentContainer">
          <div className="loginLogo">
            <div className="logoImg text-center">
              <img src={logo} alt="" />
            </div>
            <div className="text-center">
              <h4 className="mt-3">Sign in or create an account</h4>
            </div>
          </div>
          <div className="login  ">
            <div className="signIn p-4">
              <h3 className="mb-3">Confirmed successfully</h3>
              <div className="forgetpw mt-4 ">
                <div className="d-flex justify-content-center fgpw">
                  <img src={confirm} alt="" className="mb-4 img-fluid" />
                </div>
                <h5>
             Great  you can login now !!
                </h5>
                <h6 className="text-muted">you have successfully confirmed your new password</h6>
              </div>
              <div className="loginForm mt-4">
         
                <div className="d-flex justify-content-center mt-4 mb-5">
                  <button className="signInBtn" onClick={()=>{
                    navigate('/login');
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('emailForget');
                  }}>
                    Happy Shopping <IoIosArrowForward/>
                  </button>
                </div>

                
              </div>
            </div>
            <div className="signInImg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPassword;
