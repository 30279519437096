import React from 'react'
import logo from '../../Images/logo.png';
import TextField from '@mui/material/TextField';
import { BsPerson } from 'react-icons/bs';
import { BsMinecartLoaded } from 'react-icons/bs';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
export default function TopBar() {
    return (
        <div className='topBarContiner'>

            <div className='logo'>
                <img src={logo} alt="" />
            </div>
            <div className='searchBar'>
                <TextField
                    placeholder='Search'
                    label=""
                    type="Text"
                    className='searchInp inputRounded'
                />
            </div>
            <div className="topBarIcons">
                <div>
                    <BsPerson className='topBarIcon' />
                </div>
                <div>
                    <MdOutlineFavoriteBorder className='topBarIcon' />
                </div>
                <div className='position-relative'>
                    <BsMinecartLoaded className='topBarIcon' />
                    <div className='cartCircle'>2</div>
                </div>

            </div>
        </div>
    )
}
