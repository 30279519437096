import React from 'react'
import Login from './Components/Login/Login'
import { Route, Routes } from "react-router-dom";
import Register from './Components/Register/Register';
import Home from './Components/Home/Home'
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import ForgetPasswordVerification from './Components/ForgetPasswordVerification/ForgetPasswordVerification';
import RegisterVerification from './Components/RegisterVerification/RegisterVerification';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import ConfirmationPassword from './Components/ConfirmationPassword/ConfirmationPassword';
import Test from './Components/test/Test'
export default function App() {
  return (
    <div>

      <Routes>
      <Route path="/" element={<Login/>}/>

        <Route path="login" element={<Login/>}/>
        <Route path="register" element={<Register />} />
        <Route path="home" element={<Home/>}/>
        {/* <Route path="*" element={<Home/>}/> */}
        <Route path='forgetpassword' element={<ForgetPassword/>}/>
        <Route path='forgetpasswordverifiy' element={<ForgetPasswordVerification/>}/>
        <Route path='registerverifiy' element={<RegisterVerification/>}/>
        <Route path='resetPassword' element={<ResetPassword/>}/>
        <Route path='confirmPassword' element={<ConfirmationPassword/>}/>

      </Routes> 
    </div>
  )
}
