import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import resetpw from "../../Images/resetPw.png";
import logo from "../../Images/logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { isPass, isNotEmpty } from "../../Providers/InputValidator";
import Alert from "@mui/material/Alert";

const ResetPassword = () => {
  let authToken = localStorage.getItem("userToken");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  // const [equalityErr, setEqualityErr] = useState(false);
  const [errors, setErrors] = useState();

  let navigate = useNavigate();
  const [data, setData] = useState({
    password: { value: "", error: false },
    password_confirmation: { value: "", error: false },
  });
  const handleTogglePassword = () =>
    setShowPassword((showPassword) => !showPassword);
  const handleToggleConfirmPassword = () =>
    setConfirmPassword((showConfirmPassword) => !showConfirmPassword);

  const getData = (e) => {
    let newData = { ...data };
    newData[e.target.name] = { error: false, value: e.target.value };
    setData(newData);
  };
  const handleVerif = () => {
    let { password, password_confirmation } = data;
    let newData = { ...data };
    let allIsOK = true;

    if (!isPass(password.value) || !isNotEmpty(password.value)) {
      newData = {
        ...newData,
        password: { ...newData.password, error: true },
      };
      allIsOK = false;
    }
    if (
      !isPass(password_confirmation.value) ||
      !isNotEmpty(password_confirmation.value)
    ) {
      newData = {
        ...newData,
        password_confirmation: { ...newData.password_confirmation, error: true },
      };
      allIsOK = false;
    }

    setData({ ...newData });
    return allIsOK;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("password", data.password.value);
    formData.append("password_confirmation", data.password_confirmation.value);
    // if (data.password.value !== data.password_confirmation.value) {
    //   setEqualityErr(true);
    // }
    if (handleVerif()) {
      try {
        let { data } = await axios.post(
          `https://eclickme.com/public/api/mobile/auth/update-password`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
            responseType: "json",
          }
        );
        if (data.code === 200) {
          //   localStorage.setItem("userEmailVerify", data.data.user.email);
          // localStorage.setItem('userToken', data.data.Token.plainTextToken);
          navigate("/confirmPassword");
          // console.log(data);
        }
      } catch (error) {
        setErrors(error.response.data.errors.password);
      }
    }
  };
  useEffect(() => {
    setErrors(null);
  }, [data]);
  return (
    <div>
      <div className="loginContainer">
        <div className="loginContentContainer">
          <div className="loginLogo">
            <div className="logoImg text-center">
              <img src={logo} alt="" />
            </div>
            <div className="text-center">
              <h4 className="mt-3">Sign in or create an account</h4>
            </div>
          </div>
          <div className="login  ">
            <div className="signIn p-4">
              <h3 className="mb-3">Create new password</h3>
              <div className="forgetpw mt-4 ">
                <div className="d-flex justify-content-center fgpw">
                  <img src={resetpw} alt="" className="mb-4 img-fluid" />
                </div>
                <h6>
                  Your new password must be different from previous used
                  password
                </h6>
              </div>
              {/* {equalityErr == true ? (
                <div>
                  <Alert severity="error" style={{ borderRadius: "10px" }}>
                    Password and Password Confirmation must be the same !
                  </Alert>
                </div>
              ) : (
                ""
              )} */}
              {errors &&
                errors.map((err, index) => {
                  return (
                    <div key={index}>
                      <Alert severity="error" style={{ borderRadius: "10px" }}>
                        {err}
                      </Alert>
                    </div>
                  );
                })}
              <div className="loginForm mt-4">
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="emailInput inputRounded"
                  onChange={getData}
                  value={data.password.value}
                  error={data.password.error}
                  helperText={
                    data.password.error
                      ? "Password can't be empty or less than 8 characters and at least one uppercase and one lowercase characters. "
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                      >
                        {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="password_confirmation"
                  className="emailInput inputRounded  mt-3"
                  onChange={getData}
                  value={data.password_confirmation.value}
                  error={data.password_confirmation.error}
                  helperText={
                    data.password_confirmation.error
                      ? "Password Confirmation can't be empty or less than 8 characters and at least one uppercase and one lowercase characters. "
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleConfirmPassword}
                      >
                        {showConfirmPassword ? (
                          <AiFillEye />
                        ) : (
                          <AiFillEyeInvisible />
                        )}
                      </IconButton>
                    ),
                  }}
                />

                <div className="d-flex justify-content-center mt-4 mb-5">
                  <button className="signInBtn" onClick={handleFormSubmit}>
                    Submit
                  </button>
                </div>

                <div className="newUserBtn mt-5">
                  Remember password ?{" "}
                  <span
                    style={{ color: "#ED4C14", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {" "}
                    Sign in
                  </span>{" "}
                </div>
              </div>
            </div>
            <div className="signInImg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
