import React, { useEffect, useState } from "react";
import sectionArrow from "../../Images/sectionArrow.png";
import pro1 from "../../Images/pro1.png";
import pro2 from "../../Images/pro2.png";
import pro3 from "../../Images/pro3.png";
import { BsFillStarFill } from "react-icons/bs";
import axios from "axios";
const JustForYou = () => {
  let authToken = localStorage.getItem("userToken");
  const [forYouProducts, setForYouProducts] = useState([]);
  const getForYouProducts = async () => {
    let { data } = await axios.get(
      `https://eclickme.com/public/api/mobile/favorite/categoryUser`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    setForYouProducts(data.data[0].favoriteDetails[0].Products);
  };
  useEffect(() => {
    getForYouProducts();
  }, []);
  console.log(forYouProducts && forYouProducts);

  return (
    <div
      style={{ width: "100%", borderTop: "1px solid #707070" }}
      className="mt-4"
    >
      <div className="sectionHeader ps-5 pe-5 d-flex justify-content-between">
        <div className="sectionTitle pt-4">
          <h5>Just For You</h5>
          <img src={sectionArrow} alt="" />
        </div>
        <div className="pt-4">
          <h5 style={{ color: "#ED4C14" }}>View All</h5>
        </div>
      </div>
      <div className=" w-100 d-flex justify-content-between ps-5 pe-5 mt-3 flex-wrap">
        {forYouProducts &&
          forYouProducts.map((product, index) => {
            return (
              <div className="product" key={index}>
                <img src={product.image} alt="" className="img-fluid" />
                <div className="productDetails p-3 ">
                  <p style={{ fontSize: "18px" }}>{product.description.en}</p>
                  <div className="prodcutPriceRate">
                    <p>${product.discount}</p>
                    <div className="productRate">
                      {/* {product.reviews &&
                        product.reviews.map((review, index) => {
                          return review.stars/5
                        })}{" "} */}
                        4.5
                      <BsFillStarFill className="ms-1" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {/* <div className=" w-100 d-flex justify-content-between ps-5 pe-5 mt-3 ">
        <div className="product">
          <img src={pro1} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="product">
          <img src={pro2} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="product">
          <img src={pro3} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="product">
          <img src={pro2} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-100 d-flex justify-content-between ps-5 pe-5 mt-3 ">
        <div className="product">
          <img src={pro1} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="product">
          <img src={pro2} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="product">
          <img src={pro3} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="product">
          <img src={pro2} alt="" className="img-fluid" />
          <div className="productDetails p-3 ">
            <p style={{ fontSize: "18px" }}>
              Basic Colored Sweatpants With Elastic Hems
            </p>
            <div className="prodcutPriceRate">
              <p>$10.90</p>
              <div className="productRate">
                4.9 <BsFillStarFill className="ms-1" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default JustForYou;
