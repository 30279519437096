import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import fgpw from "../../Images/forgetpw.png";
import logo from "../../Images/logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isEmail, isNotEmpty } from "../../Providers/InputValidator";
import Alert from "@mui/material/Alert";
const ForgetPassword = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState();

  const [user, setUser] = useState({
    email: { value: "", error: false },
  });
  const getUser = (e) => {
    setUser({
      ...user,
      [e.target.name]: { error: false, value: e.target.value },
    });
  };
  const handleVerif = () => {
    let { email } = user;
    let newUser = { ...user };
    let allIsOK = true;

    if (!isEmail(email.value) || !isNotEmpty(email.value)) {
      newUser = {
        ...newUser,
        email: { ...newUser.email, error: true },
      };
      allIsOK = false;
    }
    setUser({ ...newUser });
    return allIsOK;
  };
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", user.email.value);
    if (handleVerif()) {
      try {
        let { data } = await axios.post(
          `https://eclickme.com/public/api/mobile/auth/forget-password`,
          formData
        );
        if (data.code === 200) {
          navigate("/forgetpasswordverifiy");
          localStorage.setItem("emailForget", user.email.value);
        }
      } catch (error) {
        if (error.response.data.message === "The selected email is invalid.") {
          setErrors(
            "The email address you entered isn't connected to an account."
          );
        }
      }
    }
  };
  useEffect(() => {
    setErrors(null);
  }, [user]);
  return (
    <div>
      <div className="loginContainer">
        <div className="loginContentContainer">
          <div className="loginLogo">
            <div className="logoImg text-center">
              <img src={logo} alt="" />
            </div>
            <div className="text-center">
              <h4 className="mt-3">Sign in or create an account</h4>
            </div>
          </div>
          <div className="login  ">
            <div className="signIn p-4">
              <h3 className="mb-3">Forgot password</h3>
              <div className="forgetpw mt-4 ">
                <div className="d-flex justify-content-center fgpw">
                  <img src={fgpw} alt="" className="mb-4 img-fluid" />
                </div>
                <h6>
                  Please enter your email address to request a password reset
                </h6>
              </div>
              {errors && (
                <div>
                  <Alert severity="error" style={{ borderRadius: "10px" }}>
                    {errors}
                  </Alert>
                </div>
              )}
              <div className="loginForm mt-4">
                <TextField
                  label="Email"
                  type="email"
                  name="email"
                  className="emailInput inputRounded"
                  onChange={getUser}
                  value={user.email.value}
                  error={user.email.error}
                  helperText={
                    user.email.error
                      ? "Email can't be empty or not in email format."
                      : ""
                  }
                />

                <div className="d-flex justify-content-center mt-4 mb-5">
                  <button className="signInBtn" onClick={handleEmailSubmit}>
                    Submit
                  </button>
                </div>

                <div className="newUserBtn mt-5">
                  Remember password ?{" "}
                  <span
                    style={{ color: "#ED4C14", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {" "}
                    Sign in
                  </span>{" "}
                </div>
              </div>
            </div>
            <div className="signInImg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
