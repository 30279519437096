import React ,{useState,useEffect} from "react";
import axios from "axios";
const Header = () => {
    const [sliderImgs,setSliderImgs]=useState();
    const getSliderImgs=async()=>{
        let { data } = await axios.get(
            `https://eclickme.com/public/api/home_page_image/homepage/1`);
            setSliderImgs(data.data)
    }
useEffect(() => {
    getSliderImgs()
}, []);
  return (
    <div>
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={sliderImgs&&sliderImgs.image_one} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={sliderImgs&&sliderImgs.image_two} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={sliderImgs&&sliderImgs.image_three} class="d-block w-100" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Header;
