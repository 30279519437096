import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import otp from "../../Images/otp.png";
import logo from "../../Images/logo.png";
import { useNavigate } from "react-router-dom";
import OTPBox from "../OtpBox/OtpBox";
import axios from "axios";
import { ImArrowDown2 } from "react-icons/im";
import Alert from "@mui/material/Alert";

const ForgetPasswordVerification = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState();
  const checkPinCode = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("pin_code", localStorage.getItem("otp"));

    try {
      let { data } = await axios.post(
        `https://eclickme.com/public/api/mobile/auth/check-pin-code`,
        formData
      );
      if (data.code === 200) {
        localStorage.setItem("userToken", data.data.Token.plainTextToken);
        localStorage.removeItem("otp");
        localStorage.removeItem("userEmailVerify");
        navigate("/resetPassword");
      }
    } catch (error) {
      if (error.response.data.message === "Invalid pin_code") {
        setErrors(`Incorrect pin code you can resend code.`);
      }
    }
  };
  const resendCode= async()=>{
    const formData = new FormData();
    formData.append("email",localStorage.getItem("emailForget"));
        let { data } = await axios.post(
          `https://eclickme.com/public/api/mobile/auth/forget-password`,
          formData
        );
        if (data.code === 200) {
            // localStorage.removeItem('emailForget')
        }
     
  }


  return (
    <div>
      <div className="loginContainer">
        <div className="loginContentContainer">
          <div className="loginLogo">
            <div className="logoImg text-center">
              <img src={logo} alt="" />
            </div>
            <div className="text-center">
              <h4 className="mt-3">Sign in or create an account</h4>
            </div>
          </div>
          <div className="login  ">
            <div className="signIn p-4">
              <h3 className="mb-3">Forgot password</h3>
              <div className="forgetpw mt-4 ">
                <div className="d-flex justify-content-center fgpw">
                  <img src={otp} alt="" className="mb-4 img-fluid" />
                </div>
                <h4>Verification</h4>
                <h6 className="text-muted">
                  We have emailed your password reset link!{" "}
                </h6>
              </div>
              {errors && (
                <div>
                  <Alert severity="error" style={{ borderRadius: "10px" }}>
                    {errors} <ImArrowDown2/>
                  </Alert>
                </div>
              )}
              <div className="loginForm mt-4">
                <OTPBox  />

                <div className="d-flex justify-content-center mt-4 mb-5">
                  <button className="signInBtn" onClick={checkPinCode}>
                    Submit
                  </button>
                </div>

                <div
                  className="newUserBtn mt-5 fw-bold"
                  style={{ color: "#ED4C14",cursor:'pointer' }}
                  onClick={resendCode}
                >
                  Resend Code?
                </div>
              </div>
            </div>
            <div className="signInImg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordVerification;
