import React from 'react'
import {FiMenu} from 'react-icons/fi'
export default function Navbar() {
  return (
    <div>


<nav class="navbar navbar-expand-lg bg-dark ps-5">
  <div class="container-fluid"  style={{color:'white !important'}}>
    <a class="navbar-brand" href="#" ><FiMenu/></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item">
          <a class="nav-link " aria-current="page" href="#">All</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " aria-current="page" href="#">Deals</a>
        </li>    <li class="nav-item">
          <a class="nav-link " aria-current="page" href="#">Best Seller</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " aria-current="page" href="#">Customer Service</a>
        </li>
        </ul>

    </div>
  </div>
</nav>
{/* <div className="bg-img">
  <button>Shop Now</button>
</div> */}
    </div>
  )
}

