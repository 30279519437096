import React, { useEffect, useState } from "react";
import otp from "../../Images/otp.png";
import logo from "../../Images/logo.png";
import { useNavigate } from "react-router-dom";
import OTPBox from "../OtpBox/OtpBox";
import axios from "axios";
const RegisterVerification = () => {
  let navigate = useNavigate();
  let userEmailVerify = localStorage.getItem("userEmailVerify");
  // function randomFcmToken() {
  //     var result = '';
  //     var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //     var charactersLength = characters.length;
  //     for (var i = 0; i < 20; i++) {
  //         result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //     }
  //     return result;
  // }
  // let fcmToken = randomFcmToken();
  const checkPinCode = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("pin_code", localStorage.getItem("otp"));
    // formData.append("fcm_token", fcmToken);
    let { data } = await axios.post(
      `https://eclickme.com/public/api/mobile/auth/check-pin-code`,
      formData
    );
    if (data.code === 200) {
      localStorage.setItem("userToken", data.data.Token.plainTextToken);
      localStorage.removeItem("otp");
      localStorage.removeItem("userEmailVerify");
      navigate("/home");
    } else {
      console.log("error");
    }
  };
// 
  return (
    <div>
      <div className="loginContainer">
        <div className="loginContentContainer">
          <div className="loginLogo">
            <div className="logoImg text-center">
              <img src={logo} alt="" />
            </div>
            <div className="text-center">
              <h4 className="mt-3">Sign in or create an account</h4>
            </div>
          </div>
          <div className="login  ">
            <div className="signIn p-4">
              <h3 className="mb-3">Please check your email</h3>
              <div className="forgetpw mt-4 ">
                <div className="d-flex justify-content-center fgpw">
                  <img src={otp} alt="" className="mb-4 img-fluid" />
                </div>
                <h4>Verification</h4>
                <h6 className="text-muted">
                  We've send a verification code to {userEmailVerify}{" "}
                </h6>
              </div>
              <div className="loginForm mt-4">
                <OTPBox />

                <div className="d-flex justify-content-center mt-4 mb-5">
                  <button className="signInBtn" onClick={checkPinCode}>
                    Verify
                  </button>
                </div>

                {/* <div
                  className="newUserBtn mt-5 fw-bold"
                  style={{ color: "#ED4C14", cursor: "pointer" }}
                  onClick={resendCode}
                >
                  Resend Code?{" "}
                </div> */}
              </div>
            </div>
            <div className="signInImg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterVerification;
