import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import logo from "../../Images/logo.png";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  isPass,
  isEmail,
  isNotEmpty,
  isMobile,
} from "../../Providers/InputValidator";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Button } from "@mui/material";
const Login = () => {
  /*-----------------------------------------------------------------------------------------random token----------------------------------------------------------------------------------------- */
  function randomToken() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  let randToken = randomToken();
  /*-----------------------------------------------------------------------------------------random token----------------------------------------------------------------------------------------- */
  /*----------------------------------------------------------------------------------Google Login---------------------------------------------------------------------------------- */
  const postGoogleToApi = async (userFromGoogle) => {
    const formData = new FormData();
    formData.append("email", userFromGoogle.email);
    formData.append("token", randToken);
    formData.append("name", userFromGoogle.given_name);
    formData.append("login_type", "google");
    if(userFromGoogle.email!=undefined&&userFromGoogle.given_name!=undefined){

    try {
      let { data } = await axios.post(
        `https://eclickme.com/public/api/mobile/auth/login-social`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      );
      if (data.code === 200) {
        localStorage.setItem("userToken", data.data.Token.plainTextToken);
        navigate("/home");
      }
    } catch (err) {
      console.log(err);
    }}
  };

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        let { data } = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        await postGoogleToApi(data);
      } catch (err) {
        console.log(err);
      }
    },
  });

  /*----------------------------------------------------------------------------------Google Login---------------------------------------------------------------------------------- */
  /*----------------------------------------------------------------------------------Facebook Login---------------------------------------------------------------------------------- */
  
  const postFacebookToApi = async (userFromFb) => {
    const formData = new FormData();
    formData.append("email", userFromFb.email);
    formData.append("token", randToken);
    formData.append("name", userFromFb.name);
    formData.append("login_type", "facebook");

      if(userFromFb.email!=undefined&&userFromFb.name!=undefined){
        try {
          let { data } = await axios.post(
            `https://eclickme.com/public/api/mobile/auth/login-social`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
              responseType: "json",
            }
          );
          if (data.code === 200) {
            localStorage.setItem("userToken", data.data.Token.plainTextToken);
            navigate("/home");
          }
        } catch (err) {
          console.log(err);
        }
      }
    
  };
  const responseFacebook = async (response) => {
    await postFacebookToApi(response);
  };
  // const componentClicked = (data) => {
  //   console.log(data);
  // };
  /*----------------------------------------------------------------------------------Facebook Login---------------------------------------------------------------------------------- */

  /*----------------------------------------------------------------------------------Basic Login---------------------------------------------------------------------------------- */

  const [errors, setErrors] = useState();
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();
  const [user, setUser] = useState({
    email: { value: null, error: false },
    password: { value: "", error: false },
    // phone: { value: "", error: false },
  });

  const getUser = (e) => {
    setUser({
      ...user,
      [e.target.name]: { error: false, value: e.target.value },
    });
  };

  const handleVerif = () => {
    let { email, password } = user;
    let newUser = { ...user };
    let allIsOK = true;

    if (!isEmail(email.value) && !isNotEmpty(email.value) ) {
      newUser = {
        ...newUser,
        email: { ...newUser.email, error: true },
      };
      allIsOK = false;
    }

    if (!isPass(password.value) || !isNotEmpty(password.value)) {
      newUser = {
        ...newUser,
        password: { ...newUser.password, error: true },
      };
      allIsOK = false;
    }

    setUser({ ...newUser });
    return allIsOK;
  };

  async function submitLogin(e) {
    e.preventDefault();
    const formData = new FormData();
    if (isEmail(user.email.value)) {
      formData.append("email", user.email.value);
    } else {
      formData.append("phone", user.email.value);
    }
    formData.append("password", user.password.value);

    if(handleVerif()){

      try {
        let { data } = await axios.post(
          `https://eclickme.com/public/api/mobile/auth/login`,
          formData
        );
          
        if (data.code === 200) {
          localStorage.setItem("userToken", data.data.Token.plainTextToken);
          navigate("/home");
        }
      } catch (error) {
        if (error.response.data.message === "The selected email is invalid.") {
          setErrors(
            "The email address you entered isn't connected to an account. "
          );
        } else if (error.response.data.message === "Invalid data") {
          setErrors(
            "The password you entered doesn't match your email address. "
          );
        } else if (
          error.response.data.message === "The selected phone is invalid."
        ) {
          setErrors(
            "The phone number you entered isn't connected to an account. "
          );
        }
      }
    }
  }
  /*----------------------------------------------------------------------------------Basic Login---------------------------------------------------------------------------------- */

  useEffect(() => {
    setErrors(null);
  }, [user]);
  const register = () => {
    navigate("/register");
  };
  const handleTogglePassword = () =>
    setShowPassword((showPassword) => !showPassword);
  return (
    <div className="loginContainer">
      <div className="loginContentContainer">
        <div className="loginLogo">
          <div className="logoImg text-center">
            <img src={logo} alt="" />
          </div>
          <div className="text-center">
            <h4 className="mt-3">Sign in or create an account</h4>
          </div>
        </div>
        <div className="login  ">
          <div className="signIn p-4">
            <h3>Sign in</h3>
            {errors && (
              <div>
                <Alert severity="error" style={{ borderRadius: "10px" }}>
                  {errors}
                </Alert>
              </div>
            )}
            <div className="loginForm mt-4">
              {/* {withPhone ? (
                <TextField
                  label="Email or phone"
                  type="number"
                  name="phone"
                  className="emailInput inputRounded"
                  onChange={getUser}
                  value={user.phone.value}
                  error={user.phone.error}
                  helperText={user.phone.error ? "Phone can't be empty " : ""}
                />
              ) : (
                <TextField
                  label="Email or phone"
                  type="text"
                  name="email"
                  className="emailInput inputRounded"
                  onChange={getUser}
                  value={user.email.value}
                  error={user.email.error}
                  helperText={user.email.error ? "Email can't be empty " : ""}
                />
              )} */}
              {/* <TextField
                label="Email or phone"
                type="text"
                name="email"
                className="emailInput inputRounded"
                onChange={getUser}
                value={user.email.value}
                // error={user.emailorphone.error}
                // helperText={user.phoneoremail.error ? "Phone can't be empty " : ""}
              /> */}
             
                <TextField
                  label="Email or phone"
                  type="email"
                  name="email"
                  className="emailInput inputRounded"
                  onChange={getUser}
                  value={user.email.value}
                  error={user.email.error}
                  helperText={user.email.error ? "Email/phone can't be empty or not in email/phone format " : ""}
                />
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="current-password"
                className="pwInput mt-3 inputRounded"
                onChange={getUser}
                value={user.password.value}
                error={user.password.error}
                helperText={
                  user.password.error
                    ? "Password can't be empty or less than 8 characters and at least one uppercase and one lowercase characters. "
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </IconButton>
                  ),
                }}
              />
              <div className="d-flex justify-content-center mt-4">
                <button className="signInBtn" onClick={submitLogin}>
                  Sign In
                </button>
              </div>
              <div className="mt-3 d-flex justify-content-center">
                <div
                  className="forgetPw mt-2"
                  style={{ color: "#ED4C14", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forgetpassword");
                  }}
                >
                  Forget my password
                </div>
              </div>
              <div className="or d-flex  justify-content-center mt-3">
                <div className="orLine me-3"></div>
                or
                <div className="orLine ms-3"></div>
              </div>
              <div className="d-flex justify-content-center mt-4 mb-3 integration">
                <button
                  className="googleBtn mb-3"
                  id="signInBtn"
                  onClick={() => {
                    login();
                  }}
                >
                  <div>
                    <FcGoogle className="google me-2" />
                  </div>
                  <div>Continue with Google</div>
                </button>

                <FacebookLogin
                  appId="802898240788832"
                  autoLoad={false}
                  cookie={false}
                  callback={responseFacebook}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  render={(renderProps) => (
                    <button className="googleBtn" onClick={renderProps.onClick}>
                      <div>
                        <GrFacebook
                          className="facebook me-2"
                          style={{ color: "#3C5A99" }}
                        />
                      </div>
                      <div>Continue with Facebook</div>
                    </button>
                  )}
                />
              </div>
              <div className="newUserBtn">
                New user?{" "}
                <span
                  style={{ color: "#ED4C14", cursor: "pointer" }}
                  onClick={register}
                >
                  {" "}
                  Create an account
                </span>{" "}
              </div>
            </div>
          </div>
          <div className="signInImg"></div>
        </div>
      </div>

      {/*  */}
    </div>
  );
};

export default Login;
