import React from "react";
import Header from "../Header/Header";
import JustForYou from "../JustForYou/JustForYou";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../SidebaR/Sidebar";
import TopBar from "../TopBar/TopBar.";

const Home = () => {
  // const myarr=[
  //     {
  //         id:1,
  //         name:'honey',
  //         jobs:[
  //             "hey",
  //             "hello",
  //             "bye"
  //         ]
  //     },
  //     {
  //         id:2,
  //         name:'sara',
  //         jobs:[
  //             "hey1",
  //             "hello1",
  //             "bye1"
  //         ]
  //     }
  // ]
  return (
    <div>
      {/* {
                myarr.map((fav,index)=>{
              
                   return(
                    fav.jobs.map((job)=>{
                        return(
                            <div>{job}</div>
                        )
                    })
                   )
                   
                })
            } */}
      <TopBar />
      <Navbar />
      <Header />
      <JustForYou/>
    </div>
  );
};

export default Home;
