import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import logo from "../../Images/logo.png";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";
import {
  isPass,
  isEmail,
  isNotEmpty,
  isValidUsername,
  isMobile,
  isValidMailOrPhone,
} from "../../Providers/InputValidator";
import IconButton from "@mui/material/IconButton";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import jwt_decode from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const Register = () => {
  /*-----------------------------------------------------------------------------------------random token----------------------------------------------------------------------------------------- */
  function randomToken() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  let randToken = randomToken();
  /*-----------------------------------------------------------------------------------------random token----------------------------------------------------------------------------------------- */
  /*----------------------------------------------------------------------------------Google Login---------------------------------------------------------------------------------- */
  const postGoogleToApi = async (userFromGoogle) => {
    const formData = new FormData();
    formData.append("email", userFromGoogle.email);
    formData.append("token", randToken);
    formData.append("name", userFromGoogle.given_name);
    formData.append("login_type", "google");
    if(userFromGoogle.email!=undefined&&userFromGoogle.given_name!=undefined){

    try {
      let { data } = await axios.post(
        `https://eclickme.com/public/api/mobile/auth/login-social`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      );
      if (data.code === 200) {
        localStorage.setItem("userToken", data.data.Token.plainTextToken);
            navigate("/home");
      }
    } catch (err) {
      console.log(err);
    }}
  };

  const register = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        let { data } = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        await postGoogleToApi(data);
      } catch (err) {
        console.log(err);
      }
    },
  });

  /*----------------------------------------------------------------------------------Google Login---------------------------------------------------------------------------------- */
  /*----------------------------------------------------------------------------------Basic Login---------------------------------------------------------------------------------- */
  /*----------------------------------------------------------------------------------Facebook Login---------------------------------------------------------------------------------- */
  const postFacebookToApi = async (userFromFb) => {
    const formData = new FormData();
    formData.append("email", userFromFb.email);
    formData.append("token", randToken);
    formData.append("name", userFromFb.name);
    formData.append("login_type", "facebook");
    if(userFromFb.email!=undefined&&userFromFb.name!=undefined){

    try {
      let { data } = await axios.post(
        `https://eclickme.com/public/api/mobile/auth/login-social`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      );
      if (data.code === 200) {
        localStorage.setItem("userToken", data.data.Token.plainTextToken);
            navigate("/home");
      }
    } catch (err) {
      console.log(err);
    }}
  };
  const responseFacebook = async (response) => {
    await postFacebookToApi(response);

  };
  // const componentClicked = (data) => {
  //   console.log(data);
  // };
  /*----------------------------------------------------------------------------------Facebook Login---------------------------------------------------------------------------------- */

  let navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState({
    name: { value: "", error: false },
    email: { value: "", error: false },
    phone: { value: null, error: false },
    password: { value: "", error: false },
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () =>
    setShowPassword((showPassword) => !showPassword);
  const login = () => {
    navigate("/login");
  };
  useEffect(() => {
    setErrors(null);
  }, [user]);
  const getUser = (e) => {
    setUser({
      ...user,
      [e.target.name]: { error: false, value: e.target.value },
    });
  };
  const handleVerif = () => {
    let { email, password, name, phone } = user;
    let newUser = { ...user };
    let allIsOK = true;
    if (!isValidUsername(name.value) || !isNotEmpty(name.value)) {
      newUser = {
        ...newUser,
        name: { ...newUser.name, error: true },
      };
      allIsOK = false;
    }
    if (!isEmail(email.value) || !isNotEmpty(email.value)) {
      newUser = {
        ...newUser,
        email: { ...newUser.email, error: true },
      };
      allIsOK = false;
    }
    if (!isPass(password.value) || !isNotEmpty(password.value)) {
      newUser = {
        ...newUser,
        password: { ...newUser.password, error: true },
      };
      allIsOK = false;
    }
    if (!isMobile(phone.value) || !isNotEmpty(phone.value)) {
      newUser = {
        ...newUser,
        phone: { ...newUser.phone, error: true },
      };
      allIsOK = false;
    }
    setUser({ ...newUser });
    return allIsOK;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", user.name.value);
    formData.append("email", user.email.value);
    formData.append("password", user.password.value);
    formData.append("phone", user.phone.value);

    if (handleVerif()) {
      try {
        let { data } = await axios.post(
          `https://eclickme.com/public/api/mobile/auth/register`,
          formData
          // {
          //   headers: {
          //     "Content-Type": "application/json",
          //     xsrfHeaderName: "X-CSRFToken",
          //   },
          // }
        );
        console.log(data);
        if (data.code === 200) {
          localStorage.setItem("userEmailVerify", data.data.user.email);
          // localStorage.setItem('userToken', data.data.Token.plainTextToken);
          navigate("/registerverifiy");
          console.log(data);
        }
      } catch (error) {
        // console.log("error");
        // setErrors(error.errors)
        setErrors(error.response.data.errors);
      }
    }
  };
  return (
    <div className="loginContainer">
      <div className="loginContentContainer">
        <div className="loginLogo">
          <div className="logoImg text-center">
            <img src={logo} alt="" />
          </div>
          <div className="text-center">
            <h4 className="mt-3">Sign in or create an account</h4>
          </div>
        </div>
        <div className="login  ">
          <div className="signIn p-4">
            <h3>Create an account</h3>

            {errors &&
              Object.values(errors).map((error, index) => {
                return (
                  <div key={index}>
                    <Alert severity="error" style={{ borderRadius: "10px" }}>
                      {error}
                    </Alert>
                  </div>
                );
              })}
            <div className="loginForm mt-4">
              <TextField
                label="Username"
                type="text"
                name="name"
                className="emailInput mb-3 inputRounded"
                onChange={getUser}
                value={user.name.value}
                error={user.name.error}
                helperText={
                  user.name.error
                    ? "Username can't be empty or more than 50 charcters"
                    : ""
                }
              />
              <TextField
                label="Email"
                type="email"
                name="email"
                className="emailInput inputRounded"
                onChange={getUser}
                value={user.email.value}
                error={user.email.error}
                helperText={
                  user.email.error
                    ? "Email can't be empty or not in email format"
                    : ""
                }
              />
              <TextField
                label="Phone"
                type="phone"
                name="phone"
                className="emailInput inputRounded mt-3"
                onChange={getUser}
                value={user.phone.value}
                error={user.phone.error}
                helperText={
                  user.phone.error
                    ? "Phone can't be empty or not in phone number format"
                    : ""
                }
              />
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="current-password"
                className="pwInput mt-3 inputRounded"
                onChange={getUser}
                value={user.password.value}
                error={user.password.error}
                helperText={
                  user.password.error
                    ? "Password can't be empty or lessthan 8 characters and at least one uppercase and one lowercase characters "
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </IconButton>
                  ),
                }}
              />
              <div className="d-flex justify-content-center mt-4">
                <button className="signInBtn" onClick={handleFormSubmit}>
                  Sign Up
                </button>
              </div>
              <div className="mt-3 d-flex justify-content-center">
                <div
                  className="forgetPw mt-2"
                  style={{ color: "#ED4C14", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forgetpassword");
                  }}
                >
                  Forget my password
                </div>
              </div>
              <div className="or d-flex  justify-content-center mt-3">
                <div className="orLine me-3"></div>
                or
                <div className="orLine ms-3"></div>
              </div>
              <div className="d-flex justify-content-center mt-4 mb-3 integration">
                <button className="googleBtn mb-3" 
                    id="signInBtn"
                    onClick={() => {
                      register();
                    }}>
                  <div>
                    <FcGoogle className="google me-2" />
                  </div>
                  <div>Continue with Google</div>
                </button>
                {/* <button
                  className="googleBtn"
              
                >
                  <div>
                    <GrFacebook
                      className="facebook me-2"
                      style={{ color: "#3C5A99" }}
                    />
                  </div>
                  <div>Continue with Facebook</div>
                </button> */}
                              <FacebookLogin
                  appId="802898240788832"
                  autoLoad={false}
                  cookie={false}
                  callback={responseFacebook}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  render={(renderProps) => (
                    <button className="googleBtn" onClick={renderProps.onClick}>
                      <div>
                        <GrFacebook
                          className="facebook me-2"
                          style={{ color: "#3C5A99" }}
                        />
                      </div>
                      <div>Continue with Facebook</div>
                    </button>
                  )}
                />
              </div>
              <div className="newUserBtn">
                Already have an account?{" "}
                <span
                  style={{ color: "#ED4C14", cursor: "pointer" }}
                  onClick={login}
                >
                  {" "}
                  Sign in
                </span>{" "}
              </div>
            </div>
          </div>
          <div className="signInImg"></div>
        </div>
      </div>
    </div>
  );
};

export default Register;
